import 'flatpickr';
import moment from 'moment';

export default class Forms {
    constructor() {
        flatpickr.l10ns.default.firstDayOfWeek = 1; // Monday

        const formSubmitHandler = (e) => {
            let formValid = true;
            let target = e.target || e.currentTarget;
            let isUmbracoForm = target.classList.contains('umbraco-form');
            let submitButton = document.querySelector('button[type="submit"]');
            let formFields = target.querySelectorAll('[data-val="true"], [data-regex]:not(#postCodeLookup), textarea[name="g-recaptcha-response"]');

            if (submitButton) {
                submitButton.disabled = true;
            }

            formFields.forEach((el, idx) => {
                let valid = this.checkElementIsValid(el);

                if (!valid) {
                    formValid = false;
                }
            });

            if (formValid && isUmbracoForm) {
                e.preventDefault();
                const currentPageId = target.querySelector('input[name="currentPageId"]');
                const data = new URLSearchParams(new FormData(target));

                let url = target.action;

                if (currentPageId !== null) {
                    url += `?currentPageId=${currentPageId.value}`;
                }

                if (url !== window.location.href) {
                    fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: data
                    }).then((response) => {
                        if (response.status === 200) {
                            response.json().then((data) => {
                                if (data.Success) {
                                    let dialog = target.querySelector('.o-dialog');
                                    if (dialog !== null) {
                                        dialog.showModal();
                                        target.reset();
                                        submitButton.disabled = false;
                                    }
                                }
                            });
                        }
                        else {
                            console.error("Form cannot be submitted");
                            submitButton.disabled = false;
                        }
                    });
                }
                else {
                    e.preventDefault();
                    submitButton.disabled = false;
                }
            }

            else {
                if (!formValid) {
                    e.preventDefault();
                    submitButton.disabled = false;
                }
            }
        };

        const forms = document.querySelectorAll('form');
        forms.forEach((el, idx) => {
            el.addEventListener('submit', formSubmitHandler);

            const formFields = el.querySelectorAll('[data-val="true"], [data-regex]');
            formFields.forEach((el2, idx2) => {
                el2.addEventListener('change', () => {
                    this.checkElementIsValid(el2);
                    if (el.classList.contains('js-disable-button')) {
                        this.checkFormIsValid(el);
                    }
                });

                el2.addEventListener('input', () => {
                    this.checkElementIsValid(el2);
                    if (el.classList.contains('js-disable-button')) {
                        this.checkFormIsValid(el);
                    }
                });
            });
        });

        const disabledForms = document.querySelectorAll('form.js-disable');
        disabledForms.forEach((el, idx) => {
            el.addEventListener('submit', (e) => {
                e.preventDefault();
            });
        });

        const fieldGroups = document.querySelectorAll('.o-field-group');
        fieldGroups.forEach((element) => {
            const singleDigitInputs = element.querySelectorAll('.o-input--single');
            singleDigitInputs.forEach((el, idx) => {
                el.addEventListener('keydown', (e) => {
                    const isNumber = isFinite(e.key);
                    const isBackspace = e.key === "Backspace";
                    const isDelete = e.key === "Delete";
                    const isArrow = e.key.startsWith("Arrow");

                    if (isNumber || isBackspace || isDelete || isArrow) {
                        return true;
                    }
                    else e.preventDefault();
                });

                el.addEventListener('input', (e) => {
                    this.nextField(e, el, idx);
                });

                el.addEventListener('paste', this.pasteData);
            });
        });

        const formRedirect = document.querySelector('.c-form__redirect');
        if (formRedirect) {
            let parentForm = formRedirect.closest('.c-form');
            let nextPageField = parentForm.querySelector('input[name="nextPage"]');
            let interventionPageField = parentForm.querySelector('input[name="interventionPage"]');
            let errorPageField = parentForm.querySelector('input[name="errorPage"]');

            if (nextPageField) {
                if (nextPageField.value !== '') {
                    window.setTimeout(() => {
                        window.location.replace(nextPageField.value);
                    }, 5000);
                }
            }
            else {
                if (errorPageField) {
                    if (errorPageField.value !== '') {
                        window.setTimeout(() => {
                            window.location.href = errorPageField.value;
                        }, 5000);
                    }
                }
            }
        }

        const addressLookup = document.querySelector('a.o-field__lookup');
        if (addressLookup) {
            addressLookup.addEventListener('click', (e) => {
                this.addressLookup(e, this);
            });
        }

        const chooseAddress = document.querySelector('select[name="chooseAddress"]');
        if (chooseAddress) {
            chooseAddress.addEventListener('change', this.getIndividualAddress.bind(this));
        }

        const addressReset = document.querySelector('.o-field__reset');
        if (addressReset) {
            addressReset.addEventListener('click', this.resetDropdowns);
        }

        const dobDay = document.querySelector('input[name="dobDay"]');
        if (dobDay !== null) {
            dobDay.addEventListener('input', this.formatDate);
        }

        const dobMonth = document.querySelector('input[name="dobMonth"]');
        if (dobMonth !== null) {
            dobMonth.addEventListener('input', this.formatDate);
        }

        const dobYear = document.querySelector('input[name="dobYear"]');
        if (dobYear !== null) {
            dobYear.addEventListener('input', this.formatDate);
        }
    }

    checkFormIsValid(form) {
        let formValid = true;
        let submitButton = form.querySelector('button[type="submit"], input[type="submit"]');
        let formFields = form.querySelectorAll('[data-val="true"], [data-regex]:not(#postCodeLookup), textarea[name="g-recaptcha-response"]');

        submitButton.disabled = true;

        formFields.forEach((el, idx) => {
            let valid = this.checkElementIsValid(el);

            if (!valid) {
                formValid = false;
            }
        });

        if (formValid) {
            submitButton.disabled = false;
        }
        else submitButton.disabled = true;
    }

    pasteData(e) {
        e.preventDefault();

        let paste = (event.clipboardData || window.clipboardData).getData('text');
        if (paste !== '') {
            paste = paste.trim();
        }
        let split = paste.split("");

        // Get all inputs
        let target = e.target || e.currentTarget;
        let parent = target.closest('.c-form__large, .o-field-group');
        let fields = parent ? parent.querySelectorAll('.o-input--single') : null;

        fields.forEach((el, idx) => {
            el.value = split[idx];
        });

        let parentParent = parent.closest('.o-field');
        let hiddenField = parentParent ? parentParent.querySelector('input[type="hidden"][data-val="true"]') : null;

        if (hiddenField) {
            hiddenField.value = paste;
        }

        target.blur();
    }

    nextField(e, el, idx) {
        let target = el;
        let parent = target.parentElement;

        let parentGroup = target ? target.closest('.o-field:not(.o-field--single)') : null;
        let hiddenField = parentGroup ? parentGroup.querySelector('input[type="hidden"][data-val="true"]') : null;

        let maxLength = parseInt(target.attributes["maxlength"].value, 10);
        let valueLength = target.value.length;

        if (valueLength >= maxLength) {
            if (hiddenField) {
                hiddenField.value = this.replaceAt(hiddenField.value, idx, target.value);
                this.checkElementIsValid(hiddenField);
            }

            let nextField = parent.nextElementSibling;
            if (nextField) {
                let newField = nextField.querySelector('.o-input--single');
                if (newField) {
                    newField.focus();
                    return;
                }
            }
            else {
                let nextQuestion = parentGroup.closest('.o-field');
                if (nextQuestion.nextElementSibling !== null) {
                    let field = nextQuestion.nextElementSibling.querySelector('.o-field .o-input');
                    if (field) {
                        field.focus();
                        return;
                    }
                }

                else {
                    let form = target.closest('form');
                    if (form) {
                        let submit = form.querySelector('button[type="submit"]');
                        if (submit) {
                            submit.focus();
                            return;
                        }
                    }
                }

                target.blur();
                return;
            }
        }
        else if (valueLength === 0) {
            if (hiddenField) {
                hiddenField.value = this.replaceAt(hiddenField.value, idx, '0');
                this.checkElementIsValid(hiddenField);
            }
        }
    }

    replaceAt(string, index, replace) {
        return string.substring(0, index) + replace + string.substring(index + 1);
    }

    checkElementIsValid(el, errorMessage) {
        let fieldValid = false;
        let fieldContainer = el.closest('.o-field');
        let validationMessage = null;
        if (fieldContainer !== null) {
            validationMessage = fieldContainer.querySelector(':scope > .field-validation-valid');
        }

        let isRecaptcha = el.name === 'g-recaptcha-response';
        if (isRecaptcha) {
            if (errorMessage === '' || errorMessage === undefined) {
                errorMessage = 'Please confirm that you are not a robot';
            }
        }

        let regex = el.getAttribute('data-regex');
        const isRegexValidation = regex !== null && regex !== '';
        const isNormalValidation = el.getAttribute('data-val') !== null;
        const isAcceptedValuesValidation = el.getAttribute('data-accepted') !== null;
        const isDateField = el.id === "DateOfBirth";
        const isDateValidation = el.id.startsWith('dob');

        let validationMessageText = el.getAttribute('data-val-required') || errorMessage;
        let validationMessageTextRegex = el.getAttribute('data-val-regex') || errorMessage;
        let validationMessageTextAccepted = el.getAttribute('data-val-accepted') || errorMessage;

        if (!isDateValidation) {
            if (!isRegexValidation) {
                if (isAcceptedValuesValidation) {
                    if (el.value === '') {
                        if (validationMessage) {
                            validationMessage.innerText = validationMessageText;
                        }
                        fieldValid = false;
                    }
                    else {
                        let acceptedValueAttribute = el.getAttribute('data-accepted');
                        let acceptedValues = acceptedValueAttribute.split(',');
                        acceptedValues = acceptedValues.map(x => x.trim().toLowerCase());

                        if (acceptedValues.includes(el.value.toLowerCase())) {
                            if (validationMessage) {
                                validationMessage.innerText = '';
                            }
                            fieldValid = true;
                        }
                        else {
                            if (validationMessage) {
                                validationMessage.innerText = validationMessageTextAccepted;
                            }
                            fieldValid = false;
                        }
                    }
                }
                else {
                    if (el.value === '') {
                        if (validationMessage) {
                            validationMessage.innerText = validationMessageText;
                        }
                        fieldValid = false;
                    }
                    else {
                        if (validationMessage) {
                            validationMessage.innerText = '';
                        }
                        fieldValid = true;
                    }
                }
            }
            else {
                if (el.value === '') {
                    if (isNormalValidation) {
                        if (validationMessage) {
                            validationMessage.innerText = validationMessageText;
                        }
                        fieldValid = false;
                    }
                    else {
                        if (validationMessage) {
                            validationMessage.innerText = '';
                        }
                        fieldValid = true;
                    }
                }

                if (isRegexValidation) {
                    if (!el.value.match(regex)) {
                        if (validationMessage) {
                            validationMessage.innerText = validationMessageTextRegex;
                        }
                        fieldValid = false;
                    }
                    else {
                        if (validationMessage) {
                            validationMessage.innerText = '';
                        }
                        fieldValid = true;
                    }
                }

                if (isAcceptedValuesValidation) {
                    let acceptedValueAttribute = el.getAttribute('data-accepted');
                    let acceptedValues = acceptedValueAttribute.split(',');
                    acceptedValues = acceptedValues.map(x => x.trim().toLowerCase());

                    if (acceptedValues.includes(el.value.toLowerCase())) {
                        if (validationMessage) {
                            validationMessage.innerText = '';
                        }
                        fieldValid = true;
                    }
                    else {
                        if (validationMessage) {
                            validationMessage.innerText = validationMessageTextAccepted;
                        }
                        fieldValid = false;
                    }
                }

                let isEmail = el.name === 'confirmEmailAddress';
                if (isEmail) {
                    var email = document.querySelector('input[name="email"]').value;
                    var confirmEmail = document.querySelector('input[name="confirmEmailAddress"]').value;

                    if (email !== confirmEmail) {
                        fieldValid = false;
                        validationMessage.innerText = "Please ensure both emails match";
                    }
                }
            }
        }

        if (isDateValidation) {
            const isDayField = el.id === "dobDay";
            const isMonthField = el.id === "dobMonth";
            const isYearField = el.id === "dobYear";

            if (el.value === '') {
                fieldValid = false;
            }

            else {
                let numberParse = parseInt(el.value);
                let isNumber = !isNaN(numberParse);

                if (isDayField) {
                    if (isNumber) {
                        if (numberParse >= 1 && numberParse <= 31) {
                            if (validationMessage) {
                                validationMessage.innerText = "";
                            }
                            fieldValid = true;
                        }
                        else {
                            if (validationMessage) {
                                validationMessage.innerText = "Please enter a number between 1 and 31";
                            }
                            fieldValid = false;
                        }
                    }
                    else {
                        if (validationMessage) {
                            validationMessage.innerText = "Please enter a number between 1 and 31";
                        }
                        fieldValid = false;
                    }
                }
                if (isMonthField) {
                    if (isNumber) {
                        if (numberParse >= 1 && numberParse <= 12) {
                            if (validationMessage) {
                                validationMessage.innerText = "";
                            }
                            fieldValid = true;
                        }
                        else {
                            if (validationMessage) {
                                validationMessage.innerText = "Please enter a number between 1 and 12";
                            }
                            fieldValid = false;
                        }
                    }
                    else {
                        if (validationMessage) {
                            validationMessage.innerText = "Please enter a number between 1 and 12";
                        }
                        fieldValid = false;
                    }
                }
                if (isYearField) {
                    if (isNumber) {
                        let currentDate = new Date();
                        let currentYear = currentDate.getFullYear();
                        if (numberParse >= 1900 && numberParse <= currentYear) {
                            if (validationMessage) {
                                validationMessage.innerText = "";
                            }
                            fieldValid = true;
                        }
                        else {
                            if (validationMessage) {
                                validationMessage.innerText = "Please enter a year between 1900 and " + currentYear;
                            }
                            fieldValid = false;
                        }
                    }
                    else {
                        if (validationMessage) {
                            validationMessage.innerText = "Please enter a year between 1900 and " + currentYear;
                        }
                        fieldValid = false;
                    }
                }
            }
        }

        if (isDateField) {
            let dobDay = document.querySelector('input[name="dobDay"]');
            let dobMonth = document.querySelector('input[name="dobMonth"]');
            let dobYear = document.querySelector('input[name="dobYear"]');

            let validDate = this.isValidDate(dobDay.value, dobMonth.value, dobYear.value);

            if (validDate) {
                const dobDate = new Date(dobYear.value, parseInt(dobMonth.value) - 1, dobDay.value);
                const currentDate = new Date();

                if (dobDate.getTime() <= currentDate.getTime()) {
                    if (validationMessage) {
                        validationMessage.innerText = "";
                    }
                    fieldValid = true;
                }
                else {
                    if (validationMessage) {
                        validationMessage.innerText = "Please enter a date that is not in the future";
                    }
                    fieldValid = false;
                }
            }
            else {
                if (validationMessage) {
                    validationMessage.innerText = "Please enter a valid date";
                }
                fieldValid = false;
            }
        }

        if (fieldContainer !== null) {
            if (fieldValid) {
                fieldContainer.classList.remove('is-invalid');
            }
            else {
                fieldContainer.classList.add('is-invalid');
            }
        }

        return fieldValid;
    }

    addressLookup(e, self) {
        e.preventDefault();

        const postCodeField = document.querySelector('input[name="postCodeLookup"]');
        const houseNumberField = document.querySelector('input[name="houseNumberLookup"]');

        const postCodeFieldIsValid = self.checkElementIsValid(postCodeField);
        const houseNumberFieldIsValid = self.checkElementIsValid(houseNumberField);

        if (postCodeField && houseNumberField) {
            if (postCodeField.value !== '' && houseNumberField.value !== '') {
                if (postCodeFieldIsValid && houseNumberFieldIsValid) {
                    const url = `/umbraco/surface/Address/GetAddress?houseNumber=${houseNumberField.value.replace(' ', '')}&postCode=${postCodeField.value.replace(' ', '')}`;
                    fetch(url, {
                        method: 'GET'
                    }).then(response => {
                        if (response.status === 200) {
                            response.json().then(data => {
                                let error = document.querySelector('[data-valmsg-for="postCodeLookup"]');
                                if (error) {
                                    error.innerText = '';
                                }

                                if (data.length !== 0) {
                                    this.populateDropdown(data);
                                }
                                else console.error("address not found");
                            });
                        }
                    });
                }
            }
            else {
                let fieldParent = null;
                if (postCodeField.value === '') {
                    fieldParent = postCodeField.closest('.o-field');
                    if (fieldParent) {
                        fieldParent.classList.add('is-invalid');
                    }
                }

                if (houseNumberField.value === '') {
                    fieldParent = houseNumberField.closest('.o-field');
                    if (fieldParent) {
                        fieldParent.classList.add('is-invalid');
                    }
                }
            }
        }
    }

    populateDropdown(data) {
        let addressLookupContainer = document.querySelector('#addressLookup');
        let addressDropdownContainer = document.querySelector('#addressDropdown');
        let addressDropdown = document.querySelector('select[name="chooseAddress"]');

        addressLookupContainer.style.display = 'none';
        addressDropdownContainer.removeAttribute('style');

        data.forEach((dat, idx) => {
            addressDropdown.options[addressDropdown.options.length] = new Option(dat.Suggestion, dat.Format, false, false);
        });
    }

    populateAddress(data) {
        let addressLine1 = document.querySelector('input[name="address.line1"]');
        if (addressLine1 !== null) {
            if (data.Line1 !== '') {
                addressLine1.value = data.Line1;
            }
            else {
                addressLine1.value = '';
            }
        }

        let addressLine2 = document.querySelector('input[name="address.line2"]');
        if (addressLine2 !== null) {
            if (data.Line2 !== '') {
                addressLine2.value = data.Line2;
            }
            else {
                addressLine2.value = '';
            }
        }

        let addressLine3 = document.querySelector('input[name="address.line3"]');
        if (addressLine3 !== null) {
            if (data.Line3 !== '') {
                addressLine3.value = data.Line3;
            }
            else {
                addressLine3.value = '';
            }
        }

        let addressTown = document.querySelector('input[name="address.town"]');
        if (addressTown !== null) {
            if (data.Town !== '') {
                addressTown.value = data.Town;
            }
            else {
                addressTown.value = '';
            }
        }

        let addressCounty = document.querySelector('input[name="address.county"]');
        if (addressCounty !== null) {
            if (data.County !== '') {
                addressCounty.value = data.County;
            }
            else {
                addressCounty.value = '';
            }
        }

        let addressCountry = document.querySelector('input[name="address.country"]');
        if (addressCountry !== null) {
            if (data.Country !== '') {
                addressCountry.value = data.Country;
            }
            else {
                addressCountry.value = '';
            }
        }

        let postCode = document.querySelector('input[name="address.postCode"]');
        if (postCode !== null) {
            if (data.Postcode !== '') {
                postCode.value = data.Postcode;
            }
            else {
                postCode.value = '';
            }
        }
    }

    getIndividualAddress(e) {
        const postCodeField = document.querySelector('input[name="postCodeLookup"]');
        const houseNumberField = document.querySelector('input[name="houseNumberLookup"]');
        const target = e.target || e.currentTarget;
        const selectedIndex = target.selectedIndex;
        if (selectedIndex !== 0) {
            const option = target.options[selectedIndex];
            let addressName = option.text;
            let addressUrl = option.value;

            const url = `/umbraco/surface/Address/GetIndividualAddressJson?addressUrl=${encodeURIComponent(addressUrl)}`;
            fetch(url, {
                method: 'GET'
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(data => {
                        if (data !== undefined && data !== null) {
                            postCodeField.value = '';
                            houseNumberField.value = '';

                            this.populateAddress(data);
                            this.resetDropdowns();
                        }
                        else console.error("address not found");
                    });
                }
            });
        }
    }

    resetDropdowns() {
        let addressLookupContainer = document.querySelector('#addressLookup');
        let addressDropdownContainer = document.querySelector('#addressDropdown');

        addressLookupContainer.removeAttribute('style');
        addressDropdownContainer.style.display = 'none';

        let addressDropdown = document.querySelector('select[name="chooseAddress"]');

        if (addressDropdown) {
            addressDropdown.innerHTML = '';
            addressDropdown.options[0] = new Option("", "", true, true);
        }
    }

    formatDate() {
        let dateFormat = "d/m/y";
        let dateFormatted = dateFormat;
        let dobDay = document.querySelector('input[name="dobDay"]');
        let dobMonth = document.querySelector('input[name="dobMonth"]');
        let dobYear = document.querySelector('input[name="dobYear"]');
        let dobField = document.querySelector('input[id="DateOfBirth"]');

        if (dobDay.value !== '') {
            let dayValue = dobDay.value;
            if (!isNaN(dayValue)) {
                dayValue = parseInt(dayValue);
                if (dayValue < 10) {
                    dayValue = "0" + dayValue;
                }
                dateFormatted = dateFormatted.replace("d", dayValue);
            }
        }

        if (dobMonth.value !== '') {
            let monthValue = dobMonth.value;
            if (!isNaN(monthValue)) {
                monthValue = parseInt(monthValue);
                if (monthValue < 10) {
                    monthValue = "0" + monthValue;
                }
                dateFormatted = dateFormatted.replace("m", monthValue);
            }
        }

        if (dobYear.value !== '') {
            dateFormatted = dateFormatted.replace("y", dobYear.value);
        }

        dobField.value = dateFormatted;
    }

    daysInMonth(m, y) {
        switch (m) {
            case 1:
                return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
            case 8: case 3: case 5: case 10:
                return 30;
            default:
                return 31;
        }
    }

    isValidDate(d, m, y) {
        d = parseInt(d);
        m = parseInt(m, 10) - 1;
        y = parseInt(y);
        return m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y);
    }
}
