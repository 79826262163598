export default class Faqs {
    constructor() {
        const faqSwitcherLinks = document.querySelectorAll('.c-tabs__switcher a');
        faqSwitcherLinks.forEach((el, idx) => {
            el.addEventListener('click', this.toggleFaqCollection);
        });
    }

    toggleFaqCollection(e) {
        e.preventDefault();
        const faqPanels = document.querySelectorAll('.c-tabs__panel');
        const faqSwitcherLinks = document.querySelectorAll('.c-tabs__switcher li');
        let self = e.target || e.currentTarget;
        if (self.nodeName === "IMG") {
            self = self.parentElement;
        }
        let link = self.getAttribute('href');
        if (link) {
            let faqSwitch = document.querySelector(link);
            if (faqSwitch) {
                faqSwitcherLinks.forEach((el, idx) => {
                    el.classList.remove('is-active');
                    if (idx === faqSwitcherLinks.length - 1) {
                        self.parentElement.classList.add('is-active');
                    }
                });

                faqPanels.forEach((el, idx) => {
                    el.classList.remove('is-active');
                    if (idx === faqPanels.length - 1) {
                        faqSwitch.classList.add('is-active');
                    }
                });
            }
        }
    }
}