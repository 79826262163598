let currentLink = "";
let sso = "";

import Forms from './_forms';

export default class Dialog {
    constructor() {
        const dialogs = document.querySelectorAll('.o-dialog');
        
        dialogs.forEach((el, idx) => {
            dialogPolyfill.registerDialog(el);
            const closeButton = el.querySelectorAll('.js-close-dialog');
            closeButton.forEach((el2, idx2) => {
                el2.addEventListener('click', (e) => {
                    let form = el2.closest('form');
                    if (form) {
                        form.reset();

                        let formFields = form.querySelectorAll('[data-val="true"], [data-regex], textarea[name="g-recaptcha-response"]');
                        formFields.forEach((el3, idx) => {
                            let parent = el3.closest('.o-field');

                            if (parent) {
                                parent.classList.remove('is-invalid');

                                let valMessage = parent.querySelector('.field-validation-valid');
                                if (valMessage) {
                                    valMessage.innerText = '';
                                }
                            }
                        });
                    }

                    el.close();
                });
            });
        });        

        let updateEmailBtn = document.getElementById("btn-update-email");            
        if (updateEmailBtn !== null) {
            updateEmailBtn.addEventListener("click", () => {
                const forms = new Forms();
                let formValid = true;
                let dialog = updateEmailBtn.closest('.o-dialog');
                let form = document.querySelector('#emailaddress form');
                let formFields = form.querySelectorAll(' [data-val="true"], [data-regex], textarea[name="g-recaptcha-response"]');

                formFields.forEach((el, idx) => {
                    let valid = forms.checkElementIsValid(el);

                    if (!valid) {
                        formValid = false;
                    }
                });

                if (formValid) {
                    this.updateEmail(this);
                    dialog.close();
                }
            });
        }

        this.initQuickLinkDialogs();
        this.initHeroBannerDialogs();
    }

    initHeroBannerDialogs() {
        const heroBannerButton = document.querySelectorAll('.c-banner .o-btn');
        const hasEmailAddressField = document.querySelector('input[id="hasEmailAddress"]');
        let hasEmailAddress = hasEmailAddressField ? JSON.parse(hasEmailAddressField.value) : false;

        let pageId = document.querySelector('input[id="pageId"]');
        let policyNumber = document.querySelector('input[id="policyNumber"]');
        let surname = document.querySelector('input[id="surname"]');

        if (pageId !== null) {
            pageId = pageId.value;
        }
        if (policyNumber !== null) {
            policyNumber = policyNumber.value;
        }
        if (surname !== null) {
            surname = surname.value;
        }        

        heroBannerButton.forEach((el, idx) => {
            el.addEventListener('click', (e) => {
                let link = el.getAttribute('href');
                let dialog;
                currentLink = link;
                hasEmailAddress = hasEmailAddressField ? JSON.parse(hasEmailAddressField.value) : false;

                if (link.startsWith('#')) {
                    e.preventDefault();

                    if (hasEmailAddress) {
                        if (link === "#antiviruskey") {
                            this.callAntiVirus(pageId, policyNumber, surname, link);
                        }
                        else {
                            dialog = document.querySelector(link);
                        }
                    }
                    else {
                        dialog = document.querySelector('#emailaddress');
                    }

                    if (dialog) {
                        dialog.showModal();
                    }
                }

                if (link === "/members/experian-sso") {
                    currentLink = link;
                    if (!hasEmailAddress) {
                        dialog = document.querySelector('#emailaddress');
                        if (dialog) {
                            e.preventDefault();
                            dialog.showModal();
                        }
                    }
                }
            });
        });
    }



    initQuickLinkDialogs() {
        const quickLinkButtons = document.querySelectorAll('.c-panel__link .o-btn');
        const hasEmailAddressField = document.querySelector('input[id="hasEmailAddress"]');
        let hasEmailAddress = hasEmailAddressField ? JSON.parse(hasEmailAddressField.value) : false;

        let pageId = document.querySelector('input[id="pageId"]');
        let policyNumber = document.querySelector('input[id="policyNumber"]');
        let surname = document.querySelector('input[id="surname"]');

        if (pageId !== null) {
            pageId = pageId.value;
        }
        if (policyNumber !== null) {
            policyNumber = policyNumber.value;
        }
        if (surname !== null) {
            surname = surname.value;
        }        

        quickLinkButtons.forEach((el, idx) => {
            el.addEventListener('click', (e) => {
                let link = el.getAttribute('href');
                let dialog;
                currentLink = link;
                hasEmailAddress = hasEmailAddressField ? JSON.parse(hasEmailAddressField.value) : false;

                if (link.startsWith('#')) {
                    e.preventDefault();

                    if (hasEmailAddress) {
                        //if (link === "#fraudreport") {
                        //    this.callExperian(policyNumber, surname, false);
                        //}
                        if (link === "#antiviruskey") {
                            this.callAntiVirus(pageId, policyNumber, surname, link);
                        }
                        else {
                            dialog = document.querySelector(link);
                        }
                    }
                    else {
                        dialog = document.querySelector('#emailaddress');
                    }

                    if (dialog) {
                        dialog.showModal();
                    }
                }

                if (link === "/members/experian-sso") {
                    currentLink = link;
                    if (!hasEmailAddress) {
                        dialog = document.querySelector('#emailaddress');
                        if (dialog) {
                            e.preventDefault();
                            dialog.showModal();
                        }
                    }
                }
            });
        });
    }

    callAntiVirus(pageId, policyNumber, surname, link) {

        let hostname = window.location.origin;
        let url = hostname + "/umbraco/surface/benefits/RequestAntiVirusKey";

        let data = '{' +
            '"pageId": "' + pageId + '",' +
            '"policyNumber": "' + policyNumber + '",' +
            '"surname": "' + surname + '",' +
            '"partner": {' +
            '"id": "Experian"' +
            '}' +
            '}';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data
        }).then((response) => {

            response.json().then(json => {
                if (json.redirectUrl) {
                    window.location.href = json.redirectUrl;
                }

                else {
                    let dialog;
                    let status = response.status;
                    if (status === 200 || status === 429) {
                        dialog = document.querySelector(link);

                        // Disable button
                    }
                    else if (status === 404) {
                        dialog = document.querySelector('#emailaddress');
                    }
                    else {
                        console.error("Unable to request antivirus key");
                    }
                    if (dialog) {
                        dialog.showModal();
                    }
                }
            });
        });

    }

    //callExperian(policyNumber, surname, skipSubmit) {

    //    let hostname = window.location.origin;
    //    let url = hostname + "/umbraco/surface/login/RetrieveSSOInfo";

    //    let data = '{' +
    //        '"policyNumber": "' + policyNumber + '",' +
    //        '"surname": "' + surname + '",' +
    //        '"partner": {' +
    //        '"id": "Experian"' +
    //        '}' +
    //        '}';

    //    fetch(url, {
    //        method: 'POST',
    //        headers: {
    //            'Content-Type': 'application/json'
    //        },
    //        body: data
    //    })
    //        .then(response => {
    //            response.json().then(json => {

    //                if (json) {
    //                    if (typeof (json) === "string" && json.includes("timeout")) {
    //                        window.location.href = json;
    //                        return false;
    //                    }
    //                }

    //                let dialog = document.querySelector('#fraudreport');
    //                if (dialog) {
    //                    let form = dialog.querySelector('form');
    //                    let hidden = form ? form.querySelector('input[type="hidden"]') : null;

    //                    if (form && hidden) {
    //                        hidden.value = json.value;
    //                        sso = hidden.value;
    //                        if (skipSubmit === false) {
    //                            form.setAttribute("method", "post");
    //                            form.setAttribute("target", "_blank");
    //                            form.submit();
    //                        }
    //                    }
    //                }

    //            });
    //        });
    //}

    updateEmail(self) {
        let hostname = window.location.origin;
        let url = hostname + "/umbraco/surface/policy/UpdateEmail";

        let policyNumber = document.querySelector('input[id="policyNumber"]');
        let surname = document.querySelector('input[id="surname"]');
        let email = document.querySelector('input[id="email"]');
        
        if (policyNumber !== null) {
            policyNumber = policyNumber.value;
        }
        if (surname !== null) {
            surname = surname.value;
        }        
        if (email !== null) {
            email = email.value;
        }

        let data = '{' +
            '"policyNumber": "' + policyNumber + '",' +
            '"surname": "' + surname + '",' +
            '"email": "' + email + '",' +
            '"partner": {' +
            '"id": "Experian"' +
            '}' +
            '}';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data
        })
            .then(response => {
                const hasEmailAddressField = document.querySelector('input[id="hasEmailAddress"]');
                if (hasEmailAddressField) {
                    hasEmailAddressField.value = "true";
                }

                const email = document.querySelector('input[id="email"]');
                if (email) {
                    email.value = '';
                }

                this.initQuickLinkDialogs();

                if (currentLink.startsWith('#')) {
                    let dialog = document.querySelector(currentLink);

                    if (dialog) {
                        dialog.showModal();
                    }
                }
                else {
                    if (currentLink === "/members/experian-sso") {
                        let dialog = document.querySelector('#fraudreport');
                        if (dialog) {
                            dialog.showModal();
                        }
                    }
                }
            });

    }

}