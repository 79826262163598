import { whichTransitionEvent } from '../functions/_animations';
import { debounce } from '../functions/_debounce';

export default class Navigation {
    constructor() {
        const activeClass = 'is-active';
        const displayClass = 'is-display-flex';
        const navActiveClass = 'is-nav-active';
        const hiddenClass = 'is-hidden';

        const navToggle = document.querySelector('.c-toggle');
        const navigation = document.querySelector('.c-navigation');
        const navTopLevelLinks = navigation ? document.querySelectorAll('.c-navigation>ul>li>a') : [];

        if (navToggle) {
            navToggle.addEventListener('click', () => {
                if (!navToggle.classList.contains(activeClass)) {
                    navToggle.classList.add(activeClass);
                    document.body.classList.add(navActiveClass);

                    navigation.classList.add(displayClass);

                    if ('requestAnimationFrame' in window) {
                        window.requestAnimationFrame(() => {
                            navigation.classList.add(activeClass);
                        });
                    }
                    else {
                        window.setTimeout(() => {
                            navigation.classList.add(activeClass);
                        }, 1000 / 60);
                    }
                }

                else {
                    navToggle.classList.remove(activeClass);
                    document.body.classList.remove(navActiveClass);
                    navigation.classList.remove(activeClass);

                    navigation.addEventListener(whichTransitionEvent(), (e) => {
                        if (!navigation.classList.contains(activeClass)) {
                            if (e.propertyName === 'opacity') {
                                navigation.classList.remove(displayClass);
                            }
                        }
                    });
                }
            });
        }

        if (navigation) {
            navTopLevelLinks.forEach((el, idx) => {
                el.addEventListener('click', (e) => {
                    //e.preventDefault();
                    if ('matchMedia' in window) {
                        if (!window.matchMedia("(min-width: 992px)").matches) {
                            let target = e.currentTarget;
                            target.parentElement.classList.toggle(hiddenClass);
                        }
                    }
                    else {
                        if (window.innerWidth < 992) {
                            let target = e.currentTarget;
                            target.parentElement.classList.toggle(hiddenClass);
                        }
                    }
                });
            });
        }

        const loginButton = document.querySelector('.c-login > ul > li > a.o-btn');
        const loginPopup = document.querySelector('.c-header .o-dialog');

        if (loginButton && loginPopup) {
            loginButton.addEventListener('click', (e) => {

                /* #11748 Always show the login options on mobile */
                e.preventDefault();
                loginPopup.showModal();
                document.activeElement.blur();

                //Set the flex direction on the panels if we're in a mobile view
                if (('matchMedia' in window && !window.matchMedia("(min-width: 992px)").matches) || window.innerWidth < 992) {
                    const loginPopupPanelsContainer = loginPopup.querySelector('.c-panels');
                    if (loginPopupPanelsContainer) {
                        loginPopupPanelsContainer.style.flexDirection = "column";
                        const loginPopupPanels = loginPopupPanelsContainer.querySelectorAll('.c-panel');
                        if (loginPopupPanels) {
                            loginPopupPanels.forEach(function (e) {
                                e.style.marginBottom = "24px";
                                const panelBody = e.querySelector('.c-panel__body > p');
                                if (panelBody) {
                                    panelBody.style.marginBottom = "12px";                                    
                                }
                            });
                        }
                    }
                }

                if (('matchMedia' in window && window.matchMedia("(max-height: 660px)").matches) || window.innerHeight < 660) {
                    const loginPopupPanelsContainer = loginPopup.querySelector('.c-panels');
                    if (loginPopupPanelsContainer) {
                        loginPopupPanelsContainer.style.flexDirection = "column";
                        const loginPopupPanels = loginPopupPanelsContainer.querySelectorAll('.c-panel');
                        if (loginPopupPanels) {
                            loginPopupPanels.forEach(function (e) {
                                e.style.marginBottom = "24px";
                                const panelBody = e.querySelector('.c-panel__body > p');
                                if (panelBody) {
                                    panelBody.style.marginBottom = "12px";
                                    panelBody.style.display = "none";
                                }
                            });
                        }
                    }
                }


            });
        }

        const navResize = () => {
            if (navigation) {
                if ('matchMedia' in window) {
                    if (window.matchMedia("(min-width: 992px)").matches) {
                        navigation.classList.remove(activeClass);
                        navToggle.classList.remove(activeClass);
                        document.body.classList.remove(navActiveClass);

                        navTopLevelLinks.forEach((el, idx) => {
                            el.parentElement.classList.remove(hiddenClass);
                        });
                    }
                }
                else {
                    if (window.innerWidth >= 992) {
                        navigation.classList.remove(activeClass);
                        navToggle.classList.remove(activeClass);
                        document.body.classList.remove(navActiveClass);

                        navTopLevelLinks.forEach((el, idx) => {
                            el.parentElement.classList.remove(hiddenClass);
                        });
                    }
                }
            }
        };

        window.addEventListener('resize', debounce((e) => {
            navResize();
        }, 100));
    }
}