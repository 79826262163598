const objectFitSupported = 'objectFit' in document.documentElement.style === true;

if (!objectFitSupported) {
    const objectFitElements = document.querySelectorAll('.js-object-fit');
    objectFitElements.forEach((el, idx) => {
        let src = el.src;
        if (src) {
            let parentEl = el.parentElement;
            el.style.display = 'none';
            parentEl.style.backgroundImage = `url(${src})`;
            parentEl.classList.add('is-object-fit');
        }
    });
}