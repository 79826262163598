import "core-js/stable";
import "regenerator-runtime/runtime";

import './_polyfills/_formData';
import './_polyfills/_fetch';
import './_polyfills/_details';
import './_polyfills/_closest';
import './_polyfills/_objectFit';
import './_polyfills/_origin';

import Navigation from './partials/_navigation';
const navigation = new Navigation();

import Forms from './partials/_forms';
const forms = new Forms();

import Tabs from './partials/_tabs';
const tabs = new Tabs();

import Dialog from './partials/_dialog';
const dialog = new Dialog();


const needHelpToggle = document.querySelector('.o-btn--help-panel');
if (needHelpToggle) {
    needHelpToggle.addEventListener('click', (e) => {
        let target = e.target || e.currentTarget;
        if (target.nodeName === 'IMG') {
            target = target.parentElement;
        }
        target.classList.toggle('is-active');

        let panel = document.querySelector('.c-panel.c-panel--aside');
        panel.classList.toggle('is-active');

        document.body.classList.toggle('is-panel-active');
    });
}

window.addEventListener("pageshow", function (event) {
    const historyPage = event.persisted ||
        (typeof window.performance !== "undefined" &&
            window.performance.navigation.type === 2);

    const isSecurePageField = document.querySelector('input[name="isSecurePage"]');
    let isSecurePage = false;
    if (isSecurePageField !== null) {
        isSecurePage = JSON.parse(isSecurePageField.value);
    }

    if (historyPage && isSecurePage) {
        // Handle page restore.
        window.location.reload();
    }
});

const hiddenSubMenus = document.querySelectorAll('.c-login__submenu-list');
if (hiddenSubMenus && hiddenSubMenus.length > 0) {
    hiddenSubMenus.forEach(function (element) {
        var revealButton = element.parentElement.querySelector("[data-toggle='dropdown']");
        if (revealButton) {
            revealButton.addEventListener("click", function (event) {
                if (element.classList.contains('hidden')) {
                    element.classList.remove('hidden');
                } else {
                    element.classList.add('hidden');
                }
            });
        }
    });
}